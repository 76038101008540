import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CommonService } from '../../shared/common.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FrontLayoutService {
    environment: any;

    constructor(private commonService: CommonService, private http: HttpClient) { }

    newsLatterSubscribe(params) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'newsLetterSubscribe/newsLetterSubscribe', params, { headers: headers });
    }

    getRssFeedData() {        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/rss', { headers: headers });
        
    }
}