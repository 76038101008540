import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TITLEROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { AdminLayoutService } from '../../layouts/admin-layout/admin-layout.service';
import { CommonService } from '../../shared/common.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { CoreHelperService } from '../../Providers/core-helper/core-helper.service';
import { environment } from '../../../environments/environment';

declare const $: any;

@Component({
    selector: 'app-backend-navbar',
    templateUrl: './backend-navbar.component.html',
    styleUrls: ['./backend-navbar.component.css']
})
export class BackendNavbarComponent implements OnInit {
    toggle = false;
    private listTitles: any[];
    private children: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    listTitle: any;
    profileImg: any;
    profileName: any;
    changePasswordForm: FormGroup;
    profileForm: FormGroup;
    submitted: boolean = false;
    submittedProfileForm: boolean = false;
    hide1 = true;
    hide2 = true;
    hide3 = true;
    file: any;
    imgURL: any;
    public imagePath;
    message: string;
    submitteduserData: boolean;
    fullName: string;
    get formControlChangePassword() { return this.changePasswordForm.controls; }
    get fData() { return this.profileForm.controls; }
    @ViewChild('file') myInputVariable: ElementRef;

    constructor(location: Location, private element: ElementRef, private fb: FormBuilder, private router: Router, public storageService: StorageService, private coreHelper: CoreHelperService, private cookieService: CookieService, public commonService: CommonService, public adminLayoutService: AdminLayoutService) {
        this.location = location;
        this.sidebarVisible = false;
        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }

    }

    ngOnInit() {
        this.listTitles = TITLEROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });

        this.defaultchangepwdForm();
        this.defaultprofileForm();
        setTimeout(() => {
            this.profileImg = this.commonService.rootData.uploadsUrl + "photos/" + this.storageService.getValue('profileImage')
            this.profileName = this.storageService.getValue('name')
        }, 0);
    }
    defaultprofileForm() {
        this.profileForm = this.fb.group({
            _id: [''],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
        });
    }
    defaultchangepwdForm() {
        this.changePasswordForm = this.fb.group({
            oldpwd: ['', [Validators.required]],
            newpwd: ['', [Validators.required, this.coreHelper.patternPasswordValidator()]],
            confirmPwd: ['', [Validators.required]],
        }, {
            validator: [this.coreHelper.MustMatch('newpwd', 'confirmPwd')]
        });
    }
    changePassword() {
        $("#change-pwd-modal").modal({ backdrop: 'static', keyboard: false, show: true });
    }
    updateChangepwd() {

        if (this.changePasswordForm.invalid) {
            this.submitted = true;
            return;
        }
        let changepwdObj = {
            "oldpwd": this.changePasswordForm.value.oldpwd,
            "newpwd": this.changePasswordForm.value.newpwd
        }
        this.adminLayoutService.changePassword(changepwdObj).subscribe((Response: any) => {

            if (Response.meta.code == 200) {
                this.logout();
                this.submitted = false;
                this.defaultchangepwdForm();
                this.commonService.notifier.notify('success', Response.meta.message);
            }
            else {
                this.commonService.notifier.notify('error', Response.meta.message);
            }
        }, (error) => {
            console.log(error);
        });
    }
    cancelChangepwd() {
        $("#change-pwd-modal").modal("hide");
        this.defaultchangepwdForm();
    }

    cancelProfile() {
        $("#profile-modal").modal("hide");
        this.defaultprofileForm();
    }
    preview(files) {

        this.file = files[0];
        if (files.length === 0)
            return;

        var mimeType = files[0].type;
        if (mimeType == "image/jpeg" || mimeType == "image/png") {
            var reader = new FileReader();
            this.imagePath = files;
            reader.readAsDataURL(files[0]);
            reader.onload = (_event) => {
                this.imgURL = reader.result;
            }
            this.message = "";
        } else {
            this.message = "Only JPEG and PNG image is supported.";
            this.imgURL = "";
            this.file = "";
            this.myInputVariable.nativeElement.value = "";
            return;
        }
    }

    removeuploadFile() {

        this.imgURL = "";
        this.file = "";
        this.myInputVariable.nativeElement.value = "";
    }

    logout() {
        this.storageService.removeValue(StorageKey.myToken);
        this.storageService.removeValue(StorageKey._id);
        this.storageService.removeValue(StorageKey.Name);
        this.storageService.removeValue(StorageKey.email);
        this.storageService.removeValue(StorageKey.roleId);
        this.storageService.removeValue(StorageKey.accountType);
        this.storageService.removeValue(StorageKey.profileImage);
        this.storageService.removeValue(StorageKey.Issellerlogin);
        this.router.navigate(['/admin/admin-login']);
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);
            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;
        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee.charAt(0) === '?') {
            titlee = titlee.slice(1);
        }
        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;

            }
        }
        if (titlee.includes('edit-blog')) {
            return 'Edit Blog';
        } else if (titlee.includes('edit-news')) {
            return 'Edit News';
        } else if (titlee.includes('edit-content')) {
            return 'Edit Content';
        }

        return 'Dashboard';
    }
}
