import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { CommonService } from '../../shared/common.service';


@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnInit {
    
    constructor() {
    }

    ngOnInit() {

    }

    showVideo() {
        this.text=true;
        this.giftBox=false;
    }
    text=false;
    giftBox=true;
}
