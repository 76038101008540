import { Component, OnInit } from "@angular/core";
declare const $: any;
@Component({
  selector: "app-accept-coockies",
  templateUrl: "./accept-coockies.component.html",
  styleUrls: ["./accept-coockies.component.css"],
})
export class AcceptCoockiesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {

    if (localStorage.getItem('acceptCookies') !== 'yes') {
      $("#acceptCookiesModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    }
    else {
      $("#acceptCookiesModal").modal('hide');
    }
  }


  acceptCookies() {
    localStorage.setItem('acceptCookies', 'yes');
    $("#acceptCookiesModal").modal('hide');
  }


}
