// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,

    
    WebAPIUrl: 'http://localhost:3006/v1/',
    uploadsUrl: 'http://localhost:3006/uploads/',
    frontendUrl: 'http://localhost:4200/',
    backendUrl: 'http://localhost:4200/admin/',
    GA_TRACKING_ID: 'UA-230593589-1'
};