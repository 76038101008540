import { Component, OnInit } from '@angular/core';
import { CommonService } from './shared/common.service';
import { AdminLayoutService } from '../app/layouts/admin-layout/admin-layout.service';
import { HttpClient } from '@angular/common/http'
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService, StorageKey } from '../app/shared/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    colour;
    constructor(private http: HttpClient, private commonService: CommonService, private spinner: NgxSpinnerService, public adminLayoutService: AdminLayoutService, public storageService: StorageService) {
        this.addGAScript();
        
        setTimeout(() => {
            if (this.storageService.getValue('colour') === '1') {
                this.colour = '#9c27b0'
            } else if (this.storageService.getValue('colour') === '2') {
                this.colour = '#00bcd4'
            } else if (this.storageService.getValue('colour') === '3') {
                this.colour = '#4caf50'
            } else if (this.storageService.getValue('colour') === '4') {
                this.colour = '#ff9800'
            } else if (this.storageService.getValue('colour') === '5') {
                this.colour = '#f44336'
            } else if (this.storageService.getValue('colour') === '6') {
                this.colour = '#e91e63'
            } else {
                this.colour = '#e91e63'
            }

        }, 0);
    }
    addGAScript() {
        let gtagScript: HTMLScriptElement = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
    }
    ngOnInit() {
    }
    
}
