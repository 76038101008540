import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { StorageService, StorageKey } from '../../shared/storage.service';

declare const $: any;

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent implements OnInit {

  constructor(public location: Location, private router: Router, public storageService: StorageService) {
    if (localStorage.getItem('acceptCookies') !== 'yes') {
      $("#acceptCookiesModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    }
    else {
      $("#acceptCookiesModal").modal('hide');
    }
  }

  ngOnInit() {
  }
}
