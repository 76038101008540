import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FrontLayoutService } from '../../layouts/front-layout/front-layout.service';
import { CommonService } from '../../shared/common.service';
declare const $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  subscribeContentData = false;
  constructor(public commonService: CommonService, private fb: FormBuilder, public frontLayoutService: FrontLayoutService) {
    if (localStorage.getItem('acceptCookies') !== 'yes') {
      $("#acceptCookiesModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    }
    else {
      $("#acceptCookiesModal").modal('hide');
    }
  }

  ngOnInit() {
  }

  newsLetterSubscribeForm = this.fb.group({
    email: ['', Validators.required],
  })

  newsLetterSubscribe() {
    if (this.newsLetterSubscribeForm.invalid) {
      this.subscribeContentData = true;
      return;
    }
    let params = {
      email: this.newsLetterSubscribeForm.value.email
    }
    this.frontLayoutService.newsLatterSubscribe(params).subscribe((Response: any) => {

      if (Response.meta.code == 200) {
        this.newsLetterSubscribeForm.reset();
        this.subscribeContentData = false;
        this.commonService.notifier.notify('success', 'Thank you for subscribing to our newsletter service!');
      }
      else {
        this.commonService.notifier.notify('error', Response.meta.message);
      }
    }, (error) => {
      console.log(error);
    });
  }
}
