import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/Subscription';
import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;
import { from } from 'rxjs';

@Component({
    selector: 'app-front-layout',
    templateUrl: './front-layout.component.html',
    styleUrls: ['./front-layout.component.scss'],
})
export class FrontLayoutComponent implements OnInit {

    constructor(public location: Location, private router: Router, public storageService: StorageService, private cookieService: CookieService) {
        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }
    }

    ngOnInit() {
        let sectionId = this.storageService.getValue('sectionId');
        this.storageService.removeValue('sectionId');
        if (sectionId) {
            setTimeout(() => {                           //<<<---using ()=> syntax
                document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
                sectionId = '';
            }, 1100);
        }
    }

    scrolltoTop() {
        window.scroll({ top: 0, behavior: 'smooth' });
    }
}
