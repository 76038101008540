import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/Subscription';
import PerfectScrollbar from 'perfect-scrollbar';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CommonService } from '../../shared/common.service';

declare const $: any;
import { from } from 'rxjs';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
    private _router: Subscription;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    colour;
    sidebarImage;

    constructor(public location: Location, private router: Router, public storageService: StorageService, private cookieService: CookieService, private http: HttpClient, private commonService: CommonService,) {

        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }

        this.tokenCheck();
        setTimeout(() => {
            if (this.storageService.getValue('colour') === '1') {
                this.colour = 'purple'
            } else if (this.storageService.getValue('colour') === '2') {
                this.colour = 'azure'
            } else if (this.storageService.getValue('colour') === '3') {
                this.colour = 'green'
            } else if (this.storageService.getValue('colour') === '4') {
                this.colour = 'orange'
            } else if (this.storageService.getValue('colour') === '5') {
                this.colour = 'danger'
            } else if (this.storageService.getValue('colour') === '6') {
                this.colour = 'rose'
            } else {
                this.colour = 'rose'
            }
            if (!!this.storageService.getValue('sidebarImage')) {
                this.sidebarImage = environment.uploadsUrl + "photos/" + this.storageService.getValue('sidebarImage')
            } else {
                this.sidebarImage = "./assets/img/sidebar-3.jpg"
            }
        }, 0);

    }

    ngOnInit() {
        document.title = 'ONN Admin';
        const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {

            document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
        }
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            elemMainPanel.scrollTop = 0;
            elemSidebar.scrollTop = 0;
        });
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            let ps = new PerfectScrollbar(elemMainPanel);
            ps = new PerfectScrollbar(elemSidebar);
        }

        const window_width = $(window).width();
        let $sidebar = $('.sidebar');
        let $sidebar_responsive = $('body > .navbar-collapse');
        let $sidebar_img_container = $sidebar.find('.sidebar-background');


        if (window_width > 767) {
            if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
            }

        }

        $('.fixed-plugin a').click(function (event) {
            if ($(this).hasClass('switch-trigger')) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                }
                else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        });

        $('.fixed-plugin .badge').click(function () {
            let $full_page_background = $('.full-page-background');


            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            var new_color = $(this).data('color');

            if ($sidebar.length !== 0) {
                $sidebar.attr('data-color', new_color);
            }

            if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.attr('data-color', new_color);
            }
        });

        $('.fixed-plugin .img-holder').click(function () {
            let $full_page_background = $('.full-page-background');

            $(this).parent('li').siblings().removeClass('active');
            $(this).parent('li').addClass('active');


            var new_image = $(this).find("img").attr('src');

            if ($sidebar_img_container.length != 0) {
                $sidebar_img_container.fadeOut('fast', function () {
                    $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
                    $sidebar_img_container.fadeIn('fast');
                });
            }

            if ($full_page_background.length != 0) {

                $full_page_background.fadeOut('fast', function () {
                    $full_page_background.css('background-image', 'url("' + new_image + '")');
                    $full_page_background.fadeIn('fast');
                });
            }

            if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.css('background-image', 'url("' + new_image + '")');
            }
        });
    }
    ngAfterViewInit() {
        this.runOnRouteChange();
    }
    tokenCheck() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        this.http.get(this.commonService.rootData.rootUrl + 'adminAuth/tokenCheck', { headers: headers }).subscribe((Response: any) => {

            if (Response.meta.code == 200) {

            } else {
                this.storageService.removeValue(StorageKey.myToken);
                this.storageService.removeValue(StorageKey._id);
                this.storageService.removeValue(StorageKey.Name);
                this.storageService.removeValue(StorageKey.email);
                this.storageService.removeValue(StorageKey.roleId);
                this.storageService.removeValue(StorageKey.accountType);
                this.storageService.removeValue(StorageKey.profileImage);
                this.storageService.removeValue(StorageKey.Issellerlogin);
                this.router.navigate(['/admin/admin-login']);
            }
        }, (error) => {
            this.storageService.removeValue(StorageKey.myToken);
            this.storageService.removeValue(StorageKey._id);
            this.storageService.removeValue(StorageKey.Name);
            this.storageService.removeValue(StorageKey.email);
            this.storageService.removeValue(StorageKey.roleId);
            this.storageService.removeValue(StorageKey.accountType);
            this.storageService.removeValue(StorageKey.profileImage);
            this.storageService.removeValue(StorageKey.Issellerlogin);
            this.router.navigate(['/admin/admin-login']);
            console.log(error);
        })

    }
    isMaps(path) {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (path == titlee) {
            return false;
        }
        else {
            return true;
        }
    }
    runOnRouteChange(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
            const ps = new PerfectScrollbar(elemMainPanel);
            ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

}
