import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BackendNavbarComponent } from "./backend-navbar/backend-navbar.component";
import { AcceptCoockiesComponent } from "./accept-coockies/accept-coockies.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    BackendNavbarComponent,
    SidebarComponent,
    AcceptCoockiesComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    BackendNavbarComponent,
    SidebarComponent,
    AcceptCoockiesComponent,
  ],
})
export class ComponentsModule {}
