import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CommonService } from '../../shared/common.service';

@Injectable({
    providedIn: 'root'
})
export class AdminLayoutService {
    environment: any;

    constructor(private commonService: CommonService, private http: HttpClient) { }

    SaveuserMaster(createuserMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'adminAuth/admin-create', createuserMasterData, { headers: headers });
    }

    changePassword(updatechangepwdData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'adminAuth/changePassword-Admin', updatechangepwdData, { headers: headers });
    }

    //blog
    SaveblogMaster(createblogMasterData: any) {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'blog/Blog-create', createblogMasterData, { headers: headers });
    }

    getblogMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blog-List', { headers: headers });
    }

    getActiveBlogMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/active-Blog-List', { headers: headers });
    }

    getLatestBlog() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blogListForHomeScreen', { headers: headers });
    }

    Statusblog(updatestatusblogData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'blog/blog-statusActiveDeactive', updatestatusblogData, { headers: headers });
    }

    getblogMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blog-DeatilsById', { params: params, headers: headers });
    }


    UpdateblogMaster(updateblogMasterData: any) {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'blog/blog-update', updateblogMasterData, { headers: headers });
    }

    // content
    statusContent(updateStatusContentData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'content/get-contentActiveDeActive', updateStatusContentData, { headers: headers });
    }
    getContentMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentDataList', { headers: headers });
    }
    getContentMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentListById', { params: params, headers: headers });
    }
    getContentBySlug(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentListByslug', { params: params, headers: headers });
    }
    updateContentMaster(updateContentMasterData: any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'content/get-contentUpdate', updateContentMasterData, { headers: headers });
    }

    //news
    SavenewsMaster(createnewsMasterData: any) {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/News-create', createnewsMasterData, { headers: headers });
    }

    Statusnews(updatestatusnewsData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/newsStatus-active-deactive', updatestatusnewsData, { headers: headers });
    }
    getnewsMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsList', { headers: headers });
    }

    getActiveNewsMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsActiveList', { headers: headers });
    }

    getnewsMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsDetailsList', { params: params, headers: headers });
    }
    UpdatenewsMaster(updatenewsMasterData: any) {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/news-update', updatenewsMasterData, { headers: headers });
    }

    //contact

    saveContactMaster(saveContactMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'contact/create', saveContactMasterData, { headers: headers });
    }

    getContactMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'contact/get-contantDataList', { headers: headers });
    }

    getNewsletterMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'newsLetterSubscribe/newsLetterSubscribeList', { headers: headers });
    }
}